import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import React from 'react';

import Footer from '../components/common/Footer';
import FlexRowCenter from '../components/layout/FlexRowCenter';
import Spacer from '../components/layout/Spacer';
import MainWrapper from '../components/pages/Career/MainWrapper';

const NewsPage = () => {
  return (
    <Wrapper
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 1 }}
      exit={{ opacity: 0, y: 50 }}
    >
      <FlexRowCenter>
        <p id="News" className="title">
          News
        </p>
      </FlexRowCenter>
      <MainWrapper>
        under construction
        {/*<ul className="newslist">*/}
        {/*  {newsModels.map((news: any) => (*/}
        {/*    <li>*/}
        {/*      <FlexRowTopLeft>*/}
        {/*        <time dateTime={news.node.date}>*/}
        {/*          {String(news.node.date).replaceAll("-", ".")}*/}
        {/*        </time>*/}
        {/*        <a href={news.node.url} target="_blank">*/}
        {/*          <p>{news.node.contentText}</p>*/}
        {/*        </a>*/}
        {/*      </FlexRowTopLeft>*/}
        {/*    </li>*/}
        {/*  ))}*/}
        {/*</ul>*/}
      </MainWrapper>
      <Spacer height={'60px'}></Spacer>
      <Footer />
    </Wrapper>
  );
};

const Wrapper = styled(motion.div)`
  padding-top: min(70px, 10.764%);
  padding-bottom: min(70px, 10.347%);
  color: #ffffff;
  background-color: #204e73;

  .newslist {
    width: 80%;
    max-width: 800px;
    margin: 0 auto;
    //font-weight: 700;
    letter-spacing: 0.03em;
    line-height: 160%;
    font-size: min(1.5vw, 18px);
  }

  .newslist li {
    margin-bottom: 20px;
  }
  .newslist li a {
    text-decoration: underline;
  }

  .newslist li time {
    padding-right: 20px;
  }

  .title {
    font-size: min(99.94px, 6.94vw);
    letter-spacing: 0.03em;
    font-weight: 700;
    line-height: 160%;
    color: #f2f2f2;
  }
`;

export default NewsPage;
