import styled from '@emotion/styled';
import React from 'react';

const MainWrapper = ({ children }: { children: React.ReactNode }) => {
  return <Wrapper>{children}</Wrapper>;
};

const Wrapper = styled.div`
  @media screen and (max-width: 569px) {
    position: relative;
    width: 80%;
    margin: 0 auto;
    h1 {
      font-weight: bold;
      font-size: 24px;
    }
    .subtitle {
      color: #828282;
      font-size: 14px;
      margin: 10px 0;
    }
    .navi-box {
      display: flex;
      flex-flow: column;
      font-size: 14px;
      font-weight: bold;
      margin: 60px 0px 40px 0px;
    }
    .navi {
      margin: 8px 0;
      text-align: center;
    }
  }
  @media screen and (min-width: 570px) {
    position: relative;
    width: 80%;
    margin: 0 auto;
    h1 {
      font-weight: bold;
      font-size: 28px;
    }
    .subtitle {
      color: #828282;
      font-size: 14px;
      margin: 10px 0;
    }
    .navi-box {
      display: flex;
      flex-flow: row;
      width: 100%;
      font-size: 14px;
      font-weight: bold;
      justify-content: space-between;
      margin: 60px 0px 40px 0px;
    }
    .navi {
      text-align: center;
      min-width: 112px;
      margin: 2px 0;
    }
  }
`;

export default MainWrapper;
