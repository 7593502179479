import styled from '@emotion/styled';

const FlexRowCenter = styled.div<{
  gap?: string;
}>`
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: ${(props) => props.gap || '0'};
`;
export default FlexRowCenter;
